import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Icon, useColorModeValue, Input, InputGroup, InputLeftElement, Tooltip, Button, CircularProgress, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, VStack, Tag } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import { MdPhone } from 'react-icons/md';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import axios from 'axios';
import apiClient from 'views/admin/appclient';
interface PatientAPIResponse { items: Patient[]; }
interface Patient { _id: string; fullName: string; phoneNumber: string; enRepture: boolean; visits : any }
const EnRepture: React.FC = () => {
    const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const fetchPatients = async () => {
    const apiUrl = '/api/patient/reptures';
    try {
      const token = localStorage.getItem('token');
      if (!token) { throw new Error('Authentication token is missing.'); }
      setIsLoading(true);
      const response = await apiClient.get(apiUrl, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, }, });
      setPatients(response.data.items);
    } catch (error: any) {
      setError(error.response?.data?.message || error.message || 'An error occurred.');
    } finally { setIsLoading(false); }
  };
  useEffect(() => {
 
    fetchPatients();
  }, []);
  const filteredPatients = patients.filter((patient) => patient.enRepture && (patient.fullName.toLowerCase().includes(searchTerm.toLowerCase()) || patient.phoneNumber.includes(searchTerm)));
  const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);
  const currentPatients = filteredPatients.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const textColor = useColorModeValue('gray.700', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const handleIconClick = async (patient: Patient) => { 
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token is missing.');
        }
  
        const apiUrl = `/api/patient/reptures/${patient._id}`; // Construct the correct URL
  
        const response = await apiClient.patch(apiUrl, {}, { // Sending an empty object for the patch
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
  
        console.log('Patch successful:', response.data);  // Log the successful response
        fetchPatients();
            onClose(); // Close the modal after successful patch
  
      } catch (error: any) {
        console.error("Error patching patient:", error);
        // Handle the error, e.g., display an error message to the user.
      }
    };
  
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%" minHeight="70vh">
      <Text fontSize="xl" fontWeight="bold" mb="4">Patients en Rupture</Text>
      <Flex w="100%" mb="4" justify="space-between" align="center">
        <InputGroup w="50%"><InputLeftElement pointerEvents="none"><SearchIcon color="gray.400" /></InputLeftElement>
          <Input placeholder="Search patients" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </InputGroup>
        <Flex align="center">
          <Tooltip label="Previous page"><Button size="sm" onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} isDisabled={currentPage === 1} leftIcon={<FaArrowLeft />}>Prev</Button></Tooltip>
          <Text mx="2">{`${currentPage} / ${totalPages}`}</Text>
          <Tooltip label="Next page"><Button size="sm" onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} isDisabled={currentPage === totalPages} rightIcon={<FaArrowRight />}>Next</Button></Tooltip>
        </Flex>
      </Flex>
      {isLoading ? (<CircularProgress isIndeterminate size="lg" />) : error ? (<Text color="red.500">{error}</Text>) : filteredPatients.length === 0 ? (<Text color="gray.500">No patients en rupture found.</Text>) : (
        currentPatients.map((patient) => (
          <Flex key={patient._id} w="100%" justify="space-between" mb="4" align="center" py="2" borderBottom="1px solid" borderColor="gray.200">
            <Text fontWeight="bold" color={textColor} fontSize="md">{patient.fullName} - {patient.phoneNumber}</Text>
            <Button leftIcon={<Icon as={MdPhone} />} onClick={() => { setSelectedPatient(patient); onOpen(); }} aria-label={`Show more details for ${patient.fullName}`}>Show More</Button>
          </Flex>
        ))
      )}
 <Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Patient Details</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {selectedPatient && (
        <VStack align="start" spacing={4}>
          <VStack spacing={2}>  {/* Full Name with potential "En Rupture" tag */}
            <Text fontWeight="bold">Full Name:</Text>
            <Text>{selectedPatient.fullName}</Text>
            {selectedPatient.enRepture && <Tag size="sm" colorScheme="red">En Rupture</Tag>}
          </VStack>
          <Text><Text fontWeight="bold">Phone Number:</Text> {selectedPatient.phoneNumber}</Text>


          <Box>
            <Text fontWeight="bold" mb={2}>Present Visits:</Text>
            {selectedPatient.visits?.present?.map((visitGroup: any[], index: React.Key) => (
              <VStack align="start" pl={4} key={index} spacing={1}> {/* Added spacing and padding */}
                {visitGroup.map((visit) => (
                  <Text key={visit._id}>
                    {visit.daysOfWeek.join(', ')} ({visit.startHour} - {visit.endHour})
                  </Text>
                ))}
              </VStack>
            ))}
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>Absent Visits:</Text>
            {selectedPatient.visits?.absent?.map((visitGroup: any[], index: React.Key) => (
                <VStack align="start" pl={4} key={index} spacing={1}> {/* Added spacing and padding */}
                {visitGroup.map((visit) => (
                    <Text key={visit._id}>
                      {visit.daysOfWeek.join(', ')} ({visit.startHour} - {visit.endHour})
                    </Text>
                ))}
              </VStack>
            ))}
          </Box>

          <Button mt={4} colorScheme="blue" onClick={() => handleIconClick(selectedPatient)}>
            Supprimer de liste de repture 
          </Button>
        </VStack>
      )}
    </ModalBody>
  </ModalContent>
</Modal>
    </Card>
  );
};
export default EnRepture;