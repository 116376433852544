import { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventCard from "./EventCard";
import appclient from "views/admin/appclient";
import moment from 'moment';
import 'moment/locale/fr';
import KineLegend from "./KineLegend";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './calander.css';
const localizer = momentLocalizer(moment);
const CalendarPage = () => {
  const token = localStorage.getItem("token");
  const [sessions, setSessions] = useState([]);
  const [team, setTeam] = useState([]);
  const [selectedPhysiotherapist, setSelectedPhysiotherapist] = useState("ALL");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    fetchTeam();
    fetchSessions();
  }, [token, selectedPhysiotherapist]);

  const getFormattedDate = (fullDateString: string): string => {
    const matchDate = fullDateString.match(/^(\d{4}-\d{2}-\d{2})$/);
    const matchDateString = fullDateString.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
    
    if (matchDateString) {
      const day = matchDateString[1].padStart(2, '0');
      const month = matchDateString[2].padStart(2, '0');
      const year = matchDateString[3];
      return `${year}-${month}-${day}`;
    } else if (matchDate) {
      return matchDate[0];
    } else {
      return '';
    }
  };

  const transformSessionData = (visit: any) => {
    const patientId = visit.patient ;


    const newSessions = visit.schedule.flatMap((entry: any) => {
      const daysOfWeek = entry.daysOfWeek;

      return daysOfWeek.map((dayOfWeek: any) => {
        if (dayOfWeek && dayOfWeek.trim()) {
          const formattedDate = getFormattedDate(dayOfWeek);

          const startHour = entry.startHour?.trim() || '00:00';
          const endHour = entry.endHour?.trim() || '00:00';

          const modifiedStart = new Date(formattedDate);
          modifiedStart.setHours(startHour.split(':')[0], startHour.split(':')[1], 0, 0);

          const modifiedEnd = new Date(formattedDate);
          modifiedEnd.setHours(endHour.split(':')[0], endHour.split(':')[1], 0, 0);

          return {
            title: visit.title,
            start: modifiedStart,
            end: modifiedEnd,
            kine: visit.kine,
            medicalCareId: visit.medicalCareId,
            visitID: visit.id,
            patient: patientId, 
            _id: entry._id,
          };
        } else {
          console.error('Invalid date format in daysOfWeek:', dayOfWeek);
          return null;
        }
      }).filter(Boolean);  // Filter out any null/undefined values
    });

    const filteredSessions = selectedPhysiotherapist === 'ALL'
      ? newSessions
      : newSessions.filter((session: { kine: { _id: any } }) => session.kine._id === selectedPhysiotherapist);

    setSessions(filteredSessions);

    return filteredSessions;
  };

  const fetchSessions = async () => {
    try {
      const res = await appclient.get("/api/visits", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      console.log("my visdity" , res.data )

      const transformedSessions = res.data.flatMap((visit: any) => transformSessionData(visit));
      setSessions(transformedSessions);
      console.log("events", transformedSessions);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchTeam = async () => {
    try {
      const res = await appclient.get("/api/resources", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const kineTeamMembers = res.data.kinés.map((kine: any) => ({
        value: kine._id.toString(),
        label: kine.firstName,
        color: kine.color
      }));
      setTeam(kineTeamMembers);

    } catch (e) {
      console.log(e);
    }
  };

  const handleEventClick = (event: any) => {
    setSelectedEvent(event);
  };

  const closeEventCard = () => {
    setSelectedEvent(null);
  };
  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };
  const eventPropGetter = (event: { kine: { color: string; }; }) => {
    const backgroundColor = event.kine.color || '#ffffff';
    
    const getTextColor = (bgColor: string) => {
      const hex = bgColor.replace('#', '');
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
      return luminance > 0.5 ? '#000000' : '#ffffff';
    };

    const textColor = getTextColor(backgroundColor);
    return {
      style: {
        backgroundColor,
        borderRadius: '5px',
        opacity: 0.8,
        color: textColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    };
  };
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSessions = sessions.filter(
    (session) =>
      session.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedPhysiotherapist === "ALL" || session.kine._id === selectedPhysiotherapist)
  );
  return (
    <div>
     <div className="filters-container">
  {/* Filter by Physiotherapist */}
  <div className="filter-item">
    <label htmlFor="physiotherapist-select">Select Physiotherapist:</label>
    <select
      id="physiotherapist-select"
      className="filter-select"
      value={selectedPhysiotherapist}
      onChange={(e) => setSelectedPhysiotherapist(e.target.value)}
    >
      <option value="ALL">All Physiotherapists</option>
      {team.map((kine) => (
        <option key={kine.value} value={kine.value}>
          {kine.label}
        </option>
      ))}
    </select>
  </div>

  {/* Kine Legend */}
  <div className="filter-item legend-container">
    <KineLegend team={team} />
  </div>

  {/* Search by Patient */}
  <div className="filter-item">
    <label htmlFor="patient-search">Search by Patient:</label>
    <input
      id="patient-search"
      className="filter-input"
      type="text"
      placeholder="Enter patient name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>

  {/* Select Date */}
  <div className="filter-item">
    <label htmlFor="date-picker">Select Date:</label>
    <DatePicker
  selected={selectedDate}
  onChange={(date : any ) => setSelectedDate(date)}
  dateFormat="dd/MM/yyyy"
  onChangeRaw={(event) => {
    // Check if event exists and cast target
    if (!event) return;
    const input = event.target as HTMLInputElement;
    if (!input) return;

    // Remove any non-digit characters
    let val = input.value.replace(/\D/g, '');

    // Limit length to DDMMYYYY (8 digits)
    if (val.length > 8) {
      val = val.substring(0, 8);
    }

    // Insert slashes after day and month
    let formatted = val;
    if (val.length > 2) {
      formatted = val.substring(0, 2) + '/' + val.substring(2);
    }
    if (val.length > 4) {
      formatted = formatted.substring(0, 5) + '/' + formatted.substring(5);
    }

    // Set the input’s value
    input.value = formatted;
  }}
/>

  </div>
</div>


      <Calendar
        events={filteredSessions}
        className="relative flex mb-6 lg:flex-wrap md:mb-5"
        defaultDate={new Date()}
        localizer={localizer}
        defaultView={Views.WEEK}
        date={selectedDate} 
        onNavigate={(date) => setSelectedDate(date)}
        views={[Views.WEEK, Views.DAY, Views.AGENDA]}
        min={new Date(0, 0, 0, 8, 0, 0)}
        max={new Date(0, 0, 0, 19, 30, 0)}
        style={{ height: "20%", borderRadius: '5px', opacity: 0.8, position: 'relative' }}
        timeslots={1}
        onSelectEvent={handleEventClick}
        culture="fr"
        messages={{
          today: "Aujourd'hui",
          previous: "précédente",
          next: "suivante",
          month: "Mois",
          tomorrow: "Demain",
          allDay: "Toute la journée",
          work_week: "Semaine de travail",
          yesterday: "Hier",
          week: "Semaine",
          day: "Jour",
          agenda: "Agenda",
          date: "Date",
          time: "Heure",
          event: "Événement",
          noEventsInRange: "Aucun événement dans cette plage",
          showMore: (total) => `+ ${total} événement(s) supplémentaire(s)`,
        }}
        eventPropGetter={eventPropGetter}
      />
      {selectedEvent && ( 

      <EventCard
        isOpen={!!selectedEvent}
        onClose={closeEventCard}
        selectedEvent={selectedEvent}
        patientId={selectedEvent?.patient}
        refresh={fetchSessions}
      />)}
    </div>
  );
};

export default CalendarPage;
