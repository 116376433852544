import { Box, Flex, Icon, SimpleGrid, Spinner, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy, MdPerson } from 'react-icons/md';

import appclient from 'views/admin/appclient';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import Tasks from 'views/admin/default/components/Tasks';
import AbsentTable from './components/absencetable';
import ListeAtt from './components/listeAtt';
import EnRepture from './components/enRepture';
import PatientsWithProlongationTable from './components/PatientsWithProlongationTable';

const miniStatsData = [
    {
        icon: <Icon w='28px' h='28px' as={MdAddTask} color='white' />,
        bg: 'linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)',
        name: 'Nombre des séances aujourd’hui',
        valueKey: 'nbrOfVisitsToday',
    },
    {
        icon: <Icon w='32px' h='32px' as={MdFileCopy} color='brand.500' />,
        bg: 'secondaryGray.300',
        name: 'Nombre des séances',
        valueKey: 'totalMedicalCareSessions',
    },
    {
        icon: <Icon w='32px' h='32px' as={MdPerson} color='brand.500' />,
        bg: 'secondaryGray.300',
        name: 'Patient en attente',
        valueKey: 'numberOfPatientsInWaitingList',
    },
];


export default function UserReports() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const [statsData, setStatsData] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    const fetchData = async () => {
        setLoading(true); // Set loading to true
        try {
            const token = localStorage.getItem('token');
            const response = await appclient.get('api/stats/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response || !response.data) {
                throw new Error(`HTTP error! Status: ${response?.status || 'Unknown'}`); //Improved error handling
            }

            setStatsData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Consider adding error handling for the UI (e.g., displaying an error message)
        } finally {
            setLoading(false); //Set loading to false regardless of success or failure
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleIconClick = async (patient: { _id: any; }) => {
        try {
            const token = localStorage.getItem('token');
            await appclient.patch('/api/patient', { _id: patient._id, isInWaitingList: false }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Patient supprimé de la liste d\'attente');
            fetchData();
        } catch (error) {
            console.error('Erreur suppression patient', error);
            // Add UI feedback for error (e.g., alert or notification)
        }
    };

    if (loading) {  // Use loading state for better UX
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner color="blue.500" size="xl" />
            </Flex>
        );
    }

    if (!statsData) {
        return <p>An unexpected error occurred.</p>; // Handle potential errors gracefully.
    }

    const renderComplexTable = (data: any, displayDataset: string) => (
        <Box p={4} bg={boxBg} borderRadius="md" boxShadow="md">
            <ComplexTable tableData={data} displayDataset={displayDataset} />
        </Box>
    );

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb='20px'>
                {miniStatsData.map((data, index) => (
                    <MiniStatistics
                        key={index}
                        startContent={
                            <IconBox w='56px' h='56px' bg={data.bg} icon={data.icon} />
                        }
                        name={data.name}
                        value={statsData[data.valueKey]}
                    />
                ))}
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                {statsData?.unpaidPatientsList && renderComplexTable(statsData.unpaidPatientsList, 'patientnonpayes')}
                {statsData?.finishedPatientsList && renderComplexTable(statsData.finishedPatientsList, 'patientnonpayesencours')}
                {statsData?.patientsWithSCList && renderComplexTable(statsData.patientsWithSCList, 'patientnonpayesterminé')}
            </SimpleGrid>

            <Flex direction={{ base: 'column', md: 'row' }} gap={4} mt={8}>
                <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                    <Tasks patientsToCall={statsData?.patientsWithNoPEC} />
                </Box>
                {statsData?.patientsWithNoPresence && (
                    <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                        <AbsentTable tableData={statsData.patientsWithNoPresence} />
                    </Box>
                )}
            </Flex>
            <Flex direction={{ base: 'column', md: 'row' }} gap={4} mb={6}>
                <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                    <ListeAtt patientsToCall={statsData.patientsInWaitingList} handleIconClick={handleIconClick} />
                </Box>
                <Box flex="1" p={4} bg={boxBg} borderRadius="md" boxShadow="md">
                    <EnRepture   />
                </Box>
            </Flex>
            <PatientsWithProlongationTable />

        </Box>
    );
}