import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Spinner,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { SearchIcon, CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import apiClient from 'views/admin/appclient';

const PatientsWithProlongationTable: React.FC = () => {
  const [patients, setPatients] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [password, setPassword] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [revenues, setRevenues] = useState([]);
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchDataPayment = async () => {
    try {
      const response = await apiClient.get("/api/stats", config);
      const revenuesArray = Object.keys(response.data.totalAmountsByPaymentType).map(paymentType => ({
        paymentType,
        totalAmountPaid: response.data.totalAmountsByPaymentType[paymentType].totalAmountPaid,
        patient: response.data.totalAmountsByPaymentType[paymentType].patient
      }));
      setRevenues(revenuesArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
const [blocking , setBlocking] = useState(false);
  const fetchPatientsWithProlongation = async (startDate: string, endDate: string) => {
    try {
      setBlocking(true);
      const response = await apiClient.get(`/api/stats/prolongation/${startDate}/${endDate}`, config);
      setPatients(response.data.patientsInRange);
      setLoading(false);
      
    } catch (error) {
      console.error('Error fetching patients with prolongation:', error);
      setLoading(false);
    }
    finally
    {
      setBlocking(false);
    }
  };

  useEffect(() => {
    fetchDataPayment();
    const currentDate = new Date();
    const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
    const lastDayOfWeek = new Date(currentDate.setDate(firstDayOfWeek.getDate() + 6));
    setStartDate(firstDayOfWeek.toISOString());
    setEndDate(lastDayOfWeek.toISOString());
    fetchPatientsWithProlongation(firstDayOfWeek.toISOString(), lastDayOfWeek.toISOString());
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleDateChange = (days: number) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    newStartDate.setDate(newStartDate.getDate() + days);
    newEndDate.setDate(newEndDate.getDate() + days);
    setStartDate(newStartDate.toISOString());
    setEndDate(newEndDate.toISOString());
    fetchPatientsWithProlongation(newStartDate.toISOString(), newEndDate.toISOString());
  };


  const [showPasswordInput, setShowPasswordInput] = useState(true);
  const handlePasswordSubmit = () => {
    // Check if the password is correct
    if (password === '') {
      // If the password is correct, show the file input
      setShowPasswordInput(false);
    } else {
      // If the password is incorrect, alert the user
      alert('Incorrect password!');
    }
  };
  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner color="blue.500" size="xl" />
      </Flex>
    );
  }
  // if (showPasswordInput) {
  //   return (
  //     <Box>
  //       <FormControl>
  //         <FormLabel>Password</FormLabel>
  //         <Input
  //           type="password"
  //           value={password}
  //           onChange={(e) => setPassword(e.target.value)}
  //         />
  //         <Button mt="2" onClick={handlePasswordSubmit}>Submit</Button>
  //       </FormControl>
  //     </Box>
  //   );
  // }
  const displayDateRange = `Semaine du ${new Date(startDate).toLocaleDateString()} au ${new Date(endDate).toLocaleDateString()}`;

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, medicalCare: string) => {
    setLoading(true);
    const file = e.target.files && e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const configform = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
  
        // Upload the file
        const response = await apiClient.post(`/upload`, formData, configform);
        // Set file name and file URL based on the response
        setFileName(file.name);
        const fileUrl = response.data.cloudinaryUrl;
        setFileUrl(fileUrl);
  
        // Update the patient record with the file URL
        const rep = await apiClient.patch(`/api/charges/update/`, {
          _id: medicalCare,
          file: fileUrl,
        } , 
        config);
        window.location.reload();
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  console.log(fileUrl);
  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="200px">
        <Spinner color="blue.500" size="xl" />
      </Flex>
    );
  }

// const handleFileUpload = (files: FileList) => {
//   // Handle file upload logic here
//   console.log('Uploaded file:', files[0]);
// };
  return (
    <>
      {/* <div style={{ marginTop: "20px" }}>
        <h2 style={{ color: "blue", fontWeight: "bold" }}>Total Revenues by Payment Type</h2>
        <div className="space-y-4">
          {revenues.map(revenue => (
            <div key={revenue.paymentType} style={{ backgroundColor: "#fff", borderRadius: "0.375rem", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)", padding: "1rem" }}>
              <h3 style={{ fontSize: "1.125rem", fontWeight: "bold", marginBottom: "0.5rem" }}>{revenue.paymentType}</h3>
              <p style={{ color: "#4b5563" }}>Total: {revenue.totalAmountPaid} Dt</p>
            </div>
          ))}
        </div>
      </div> */}

      <Box overflowX="auto">
        <Flex mb="4">
          <InputGroup mr="4" maxWidth="300px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Rcherche par nom"
              value={searchTerm}
              onChange={handleSearch} />
          </InputGroup>
          <FormControl mr="4">
            <FormLabel>Début</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)} />
          </FormControl>
          <FormControl mr="4">
            <FormLabel>Fin</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)} />
          </FormControl>
          <Tooltip label="Filter by Date Range">
            <IconButton
              aria-label="Filter by Date Range"
              icon={<CalendarIcon />}
              colorScheme="blue"
              onClick={() => fetchPatientsWithProlongation(startDate, endDate)} />
          </Tooltip>
          <Flex mb="4">
            <IconButton
              aria-label="Previous Week"
              icon={<ChevronLeftIcon />}
              colorScheme="blue"
              isDisabled={blocking}
              onClick={() => handleDateChange(-7)}
            />
            <IconButton
              aria-label="Next Week"
              isDisabled={blocking}
              icon={<ChevronRightIcon />}
              colorScheme="blue"
              onClick={() => handleDateChange(7)}
            />
          </Flex>
        </Flex>
        <Text mb="4">{displayDateRange}</Text>
        {patients?.length === 0 ? (
          <Text>Pas de prolongation dans cette semaine</Text>
        ) : (
          <Table variant="striped" colorScheme="blue" width="100%">
            <Thead>
              <Tr>
                <Th>
                  <Text fontSize="sm" fontWeight="bold" color="gray.600">
                   Nom
                  </Text>
                </Th>
                <Th>Medcin</Th>
                <Th>Date de fin PEC</Th>
                <Th>AP4</Th>
                <Th>Ajouter</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patients?.filter((patient) => patient.patient.fullName.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((patient) => (
                  <Tr key={patient._id}>
                    <Td>
                      <Text fontSize="sm" color="gray.800">
                        {patient.patient.fullName}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm" color="gray.800">
                        {patient.doctor.nom}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize="sm" color="gray.800">
                        {new Date(patient.dateDeFinPec).toLocaleDateString()}
                      </Text>
                    </Td>
                    <Td>
                      {patient.file ? (
                        <Button
                          as="a"
                          href={patient.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          colorScheme="blue"
                          size="sm"
                        >
                          AP4
                        </Button>
                      ) : (
                        <Text fontSize="sm" color="gray.500">
                          No AP4
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {!patient.file && (
                        <label htmlFor={`file-upload-${patient._id}`} style={{ display: 'inline-block' }}>
                          <input
                            id={`file-upload-${patient._id}`}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileChange(e, patient?.patient.medicalCare?.[0])}/>
                          <Button as="span" colorScheme="blue" size="sm" cursor="pointer">
                            Upload AP4
                          </Button>
                        </label>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default PatientsWithProlongationTable;
