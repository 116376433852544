import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import PrivateRoute from './PrivateRoute';
import { jwtDecode } from 'jwt-decode';

// Token check function
const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp > currentTime; // Check if token is still valid
  } catch (error) {
    return false;
  }
};

// Redirect to /auth if token is invalid
if (!isTokenValid()) {
  localStorage.removeItem('token');
  window.location.hash = '#/auth'; // Redirect using HashRouter
}

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <HashRouter>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <PrivateRoute path="/admin" component={AdminLayout} />

          <Redirect from="/" to="/admin/default" />
        </Switch>
      </HashRouter>
    </React.StrictMode>
  </ChakraProvider>,

  document.getElementById('root')
);
